$(document).ready( function() {
  if (typeof homeBgImages !== 'undefined') {
    $(".h-search").bgswitcher({
      images: homeBgImages, // Background images
      effect: "fade", // fade, blind, clip, slide, drop, hide
      interval: 6000, // Interval of switching
      loop: true, // Loop the switching
      shuffle: false, // Shuffle the order of an images
      duration: 2000, // Effect duration
      easing: "swing" // Effect easing
    });
  }
});
