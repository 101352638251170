/**
 * JS Functions Related to Navigation
 */


// Open and close navigation,
// toggle navigation icon
$('#nav-toggle').on('click', function(el) {
  var $navIcon = $('#nav-toggle i');
  el.preventDefault();
  Navigation.toggleNav();

  if ($('#nav-toggle i').hasClass('icon-nav')) {
    $($navIcon).removeClass('icon-nav').addClass('icon-close');
  } else {
    $($navIcon).removeClass('icon-close').addClass('icon-nav');
  }
});

// Mobile navfunctions should only be registered
// at screen sizes where the mobile nav is visible
enquire.register("screen and (max-width:34.375em)", {
  match: function() {
    // Make sure the nav is hidden
    $('#nav').hide();
    // Restore default state of navigation
    if ($('#nav-toggle i').hasClass('icon-close')) {
      $('#nav-toggle i').removeClass('icon-close').addClass('icon-nav');
    }
  },
  unmatch: function() {
    // Show nav and make sure dropdowns are not hidden
    $('#nav').show();
  }
});


function closeDropdowns() {
  $('.site-nav__item--open').each( function() {
    $(this).removeClass('site-nav__item--open');
    Navigation.toggleMobileDropdown( $(this).find('.dropdown') );
  });
}

$('html').click( function() {
  closeDropdowns();
});
$('.site-nav__item').click( function(e) {
  if ( !$(this).hasClass('site-nav__item--open') ) {
    closeDropdowns();
    e.stopPropagation();
    $(this).addClass('site-nav__item--open');
    Navigation.toggleMobileDropdown( $(this).find('.dropdown') );
  }
});
