var desktopAnimationPoints = {
	dotPath: {
		lineA : {
			curviness: 0,
			values: [{x: -460,	y: 160}]
		},
		lineB : {
			curviness: 0,
			values: [{x: 430,	y: 470}]
		},
		lineC : {
			curviness: 0,
			values: [{x: -485,	y: 970}]
		},
		lineD : {
			curviness: 0,
			values: [{x: 495,	y: 1315}]
		},
		lineE : {
			curviness: 0,
			values: [{x: -410,	y: 1695}]
		}
	},
	
	logoBuildBezier: {
		buildA : {
			values: [{x: -195, y: 1945}]
		},
		buildB : {
			values: [{x: -223, y: 2010}]
		},
		buildC : {
			values: [{x: -251, y: 1945}]
		},
		buildD : {
			values: [{x: -185, y:1973}]
		},
		buildE : {
			values: [{x: -263, y: 1974}]
		},
		buildF : {
			values: [{x: -195, y: 2001}]
		},
		buildG : {
			values: [{x: -223, y: 1933}]
		},
		buildH : {
			values: [{x: -252, y: 2000}]
		}
	},
	
	clockBezier: {
		from: {
			curviness: 0,
			values: [{x: 2000, y: 180}]
		},
		to: {
			curviness: 0,
			values: [{x: 200, y: 180}]
		},
		copyFrom: {
			values: [{x: 2000, y: 60}]
		},
		copyTo: {
			values: [{x: -150, y: 60}]
		}
	},
	
	handsBezier: {
		from: {
			curviness: 0,
			values: [{x: -2000, y: 1000}]
		},
		to: {
			curviness: 0,
			values: [{x: 100, y: 1000}]
		},
		copyFrom: {
			values: [{x: -2000, y: 0}]
		},
		copyTo: {
			values: [{x: 0, y: 0}]
		}
	},
	
	playoutBezier: {
		init : {
			curviness: 1.25,
			values: [{x: 100, y: 1950}]
		},
		toLogo : {
			curviness: 2,
			values: [
				{x: 200, y: 2050},
				{x: -250, y: 1945}
			]
		}
	},
	
	heartX: 550,
	heartY: 600,
	
	graph: {
		x: 550,
		yStart: 1450,
		yEnd: 1350 
	},
	
};

var tabletAnimationPoints = {
	dotPath: {
		lineA : {
			curviness: 0,
			values: [{x: -365,	y: 120}]
		},
		lineB : {
			curviness: 0,
			values: [{x: 320,	y: 350}]
		},
		lineC : {
			curviness: 0,
			values: [{x: -375,	y: 730}]
		},
		lineD : {
			curviness: 0,
			values: [{x: 360,	y: 1000}]
		},
		lineE : {
			curviness: 0,
			values: [{x: -325,	y: 1285}]
		}
	},
	
	logoBuildBezier: {
		buildA : {
			values: [{x: -195, y: 1500}]
		},
		buildB : {
			values: [{x: -223, y: 1570}]
		},
		buildC : {
			values: [{x: -251, y: 1500}]
		},
		buildD : {
			values: [{x: -185, y:1528}]
		},
		buildE : {
			values: [{x: -263, y: 1530}]
		},
		buildF : {
			values: [{x: -195, y: 1556}]
		},
		buildG : {
			values: [{x: -223, y: 1488}]
		},
		buildH : {
			values: [{x: -252, y: 1555}]
		}
	},
	
	clockBezier: {
		from: {
			curviness: 0,
			values: [{x: 2000, y: 130}]
		},
		to: {
			curviness: 0,
			values: [{x: 160, y: 130}]
		},
		copyFrom: {
			values: [{x: 2000, y: 40}]
		},
		copyTo: {
			values: [{x: -180, y: 40}]
		}
	},
	
	handsBezier: {
		from: {
			curviness: 0,
			values: [{x: -2000, y: 740}]
		},
		to: {
			curviness: 0,
			values: [{x: 100, y: 740}]
		},
		copyFrom: {
			values: [{x: -2000, y: 0}]
		},
		copyTo: {
			values: [{x: 0, y: 0}]
		}
	},
	
	playoutBezier: {
		init : {
			curviness: 1.25,
			values: [{x: 100, y: 1485}]
		},
		toLogo : {
			curviness: 2,
			values: [
				{x: 200, y: 1590},
				{x: -250, y: 1500}
			]
		}
	},
	
	graph: {
		x: 400,
		yStart: 1100,
		yEnd: 1075 
	},
	
	heartX: 400,
	heartY: 430
	
};

if(window.innerWidth >= 1001){
	var animationPoints = desktopAnimationPoints;
	var speedVariant = .75;	
} else {
	var animationPoints = tabletAnimationPoints;
	var speedVariant = .5;
}
