var Navigation = (function() {

  function toggleNav() {
    var $nav = $('#nav');

    $nav.slideToggle();
  }
  
  /**
   * Slides the passed dropdown up/down
   */
  function toggleMobileDropdown($dropdown) {
   $dropdown.slideToggle();
  }
  
  
  // explicitly return public methods when this object is instantiated
  return {
    toggleNav: toggleNav,
    toggleMobileDropdown: toggleMobileDropdown
  };
  
})();