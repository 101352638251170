if ($('body').hasClass('home')) {
	$(function(){
	
	var logoBuild = function(){
		function pathPrepare ($el) {
			var lineLength = $el[0].getTotalLength();
			$el.css("stroke-dasharray", lineLength);
			$el.css("stroke-dashoffset", lineLength);
		}
		var dig = $("#logo_copy .dig path");
		var pro = $("#logo_copy .pro path");
		pathPrepare(dig);
		pathPrepare(pro);
		
		var logo = new TimelineMax();
		var logoSpeed = .4;
		var count = 0;
		logo.add(TweenMax.to($("#dot"), logoSpeed, {css:{backgroundColor:"rgb(136,38,121)",borderColor:"rgb(136,38,121)", bezier:animationPoints.logoBuildBezier.buildA}, ease:Linear.easeNone, onComplete: dropDot, onCompleteParams: ["rgb(136,38,121)", true]}));
		logo.add(TweenMax.to($("#dot"), logoSpeed, {css:{backgroundColor:"rgb(243,229,118)",borderColor:"rgb(243,229,118)", bezier:animationPoints.logoBuildBezier.buildB}, ease:Linear.easeNone, onComplete: dropDot, onCompleteParams: ["rgb(243,229,118)"]}));
		logo.add(TweenMax.to($("#dot"), logoSpeed, {css:{backgroundColor:"rgb(162,209,115)",borderColor:"rgb(162,209,115)", bezier:animationPoints.logoBuildBezier.buildC}, ease:Linear.easeNone, onComplete: dropDot, onCompleteParams: ["rgb(162,209,115)"]}));
		logo.add(TweenMax.to($("#dot"), logoSpeed, {css:{backgroundColor:"rgb(15,93,148)",borderColor:"rgb(15,93,148)", bezier:animationPoints.logoBuildBezier.buildD}, ease:Linear.easeNone, onComplete: dropDot, onCompleteParams: ["rgb(15,93,148)"]}));
		logo.add(TweenMax.to($("#dot"), logoSpeed, {css:{backgroundColor:"rgb(251,186,135)",borderColor:"rgb(251,186,135)", bezier:animationPoints.logoBuildBezier.buildE}, ease:Linear.easeNone, onComplete: dropDot, onCompleteParams: ["rgb(251,186,135)"]}));
		logo.add(TweenMax.to($("#dot"), logoSpeed, {css:{backgroundColor:"rgb(114,195,124)",borderColor:"rgb(114,195,124)", bezier:animationPoints.logoBuildBezier.buildF}, ease:Linear.easeNone, onComplete: dropDot, onCompleteParams: ["rgb(114,195,124)"]}));
		logo.add(TweenMax.to($("#dot"), logoSpeed, {css:{backgroundColor:"rgb(39,153,184)",borderColor:"rgb(39,153,184)", bezier:animationPoints.logoBuildBezier.buildG}, ease:Linear.easeNone, onComplete: dropDot, onCompleteParams: ["rgb(39,153,184)"]}));
		logo.add(TweenMax.to($("#dot"), logoSpeed, {css:{backgroundColor:"rgb(238,105,123)",borderColor:"rgb(238,105,123)", bezier:animationPoints.logoBuildBezier.buildH}, ease:Linear.easeNone, onComplete: dropDot, onCompleteParams: ["rgb(238,105,123)", false, true]}));

		function dropDot(color, first, last) {
			var writeDigital = new TimelineMax();
			var writeProspectors = new TimelineMax();

			var tippyTop = Math.round($(this.target[0]).position().top);
			var liggityLeft = Math.round($(this.target[0]).position().left);
			$('#target').append('<div class="logo-ball" style="position: absolute; top: '+tippyTop+'px; left: '+liggityLeft+'px; background-color: '+color+'"></div>');
			if(first){
				$('#logo_copy').css({left: liggityLeft + 35+'px'});
				writeDigital.add(TweenMax.to(dig, 25, {strokeDashoffset: 0, ease:Linear.easeNone}));
				writeDigital.add(TweenMax.to(dig, 1, {stroke: "#0f5d94", ease:Linear.easeNone}), 0);
				writeProspectors.add(TweenMax.to(pro, 30, {strokeDashoffset: 0, delay: 1, ease:Linear.easeNone}));
				writeProspectors.add(TweenMax.to(pro, 1, {stroke: "#2799b8", ease:Linear.easeNone}), 0);
				$('#logo').css({left: liggityLeft-65+'px', top: tippyTop-9+'px'});
			}
			if(last){
				$('#logo').fadeIn(500);
				$(this.target[0]).remove();
				TweenMax.to("#logo_copy .dig path", 1, {fill: '#0f5d94', ease:Power1.easeInOut}, 0.1);
				TweenMax.to("#logo_copy .pro path", 1, {fill: '#2799b8', ease:Power1.easeInOut, onComplete: function(){
					$('#logo_copy p').fadeIn(500);
				}}, 0.1);
			}
		}
		
	}
		
	var clockIn = function(){
		TweenMax.set("#calendar .copy, #calendar, #clock", {visibility: 'hidden'})
		TweenMax.from($("#clock"), 1, {css:{bezier:animationPoints.clockBezier.from}, ease:Power1.easeInOut})
		
		TweenMax.to($("#clock"), 1, {css:{visibility: 'visible', bezier:animationPoints.clockBezier.to}, ease:Power1.easeInOut, onComplete: function(){
			TweenMax.set("#calendar", {css:{bezier:animationPoints.clockBezier.from}})
			TweenMax.to($("#calendar"), .5, {css:{visibility: 'visible', bezier:animationPoints.clockBezier.to}, ease:Power1.easeInOut});
			TweenMax.set("#calendar .copyA", {css:{bezier:animationPoints.clockBezier.copyFrom}})
			TweenMax.to($("#calendar .copyA"), .7, {css:{visibility: 'visible', bezier:animationPoints.clockBezier.copyTo}, delay: 0.5, ease:Power1.easeInOut})
			TweenMax.set("#calendar .copyB", {css:{bezier:animationPoints.clockBezier.copyFrom}})
			TweenMax.to($("#calendar .copyB"), .7, {css:{visibility: 'visible', bezier:animationPoints.clockBezier.copyTo}, delay: 1, ease:Power1.easeInOut})
		}})
	}
	
	var heartIn = function(){
		TweenMax.set($("#heart"), {visibility: 'visible', transform: 'matrix(1,0,0,1,'+animationPoints.heartX+','+animationPoints.heartY+')'})
		TweenMax.set($("#heart .icon"), {transform: 'matrix(0,0,0,0,0,0)'})
		TweenMax.set($("#heart .copy"), {visibility: 'hidden'})
		TweenMax.from($("#heart .icon"), 1, {css:{transform: 'matrix(0,0,0,0,0,0)'}, ease:Power1.easeInOut})
		TweenMax.to($("#heart .icon"), 1, {css:{transform: 'matrix(1,0,0,1,0,0)'}, ease:Power1.easeInOut, onComplete: function(){
			$('#heart .icon').addClass('pulse');
			TweenMax.set($("#heart .copyA"), {css:{visibility: 'visible', transform: 'scale(0)'}})
			TweenMax.to($("#heart .copyA"), .7, {css:{visibility: 'visible', transform: 'scale(1)'}, ease:Power1.easeInOut})
			TweenMax.set($("#heart .copyB"), {css:{visibility: 'visible', transform: 'scale(0)'}})
			TweenMax.to($("#heart .copyB"), .7, {css:{visibility: 'visible', transform: 'scale(1)'}, delay: 1, ease:Power1.easeInOut})
		}})
	}
	
	var handsIn = function(){
		
		TweenMax.set($("#hands"), {visibility: 'visible'})
		TweenMax.set($("#hands .copy"), {visibility: 'hidden'})
		TweenMax.from($("#hands"), 1, {css:{bezier: animationPoints.handsBezier.from}, ease:Power1.easeInOut})
		TweenMax.to($("#hands"), 1, {css:{bezier: animationPoints.handsBezier.to}, ease:Power1.easeInOut, onComplete: function(){
			$('#hands .icon').addClass('shake');
			TweenMax.set("#hands .copyA", {css:{visibility: 'visible', bezier: animationPoints.handsBezier.copyFrom}})
			TweenMax.to($("#hands .copyA"), .7, {css:{visibility: 'visible', bezier: animationPoints.handsBezier.copyTo}, delay: .5, ease:Power1.easeInOut})
			TweenMax.set("#hands .copyB", {css:{visibility: 'visible', bezier: animationPoints.handsBezier.copyFrom}})
			TweenMax.to($("#hands .copyB"), .7, {css:{visibility: 'visible', bezier: animationPoints.handsBezier.copyTo}, delay: 1, ease:Power1.easeInOut})
		}})
	}
	
	var graphIn = function(){
		TweenMax.set($("#graph"), {visibility: 'visible', transform: 'matrix3d(1, 0, 0, 0, 0, .04, 0, 0, 0, 0, 1, 0, '+animationPoints.graph.x+', '+animationPoints.graph.yStart+', 0, 1)'})
		TweenMax.to($("#graph"), 0.75, {visibility: 'visible', transform: 'matrix(1,0,0,1,'+animationPoints.graph.x+','+animationPoints.graph.yEnd+')', onComplete: function(){
			$('#graph').addClass('raise');
		}});
	}
	
	var playout = function(){
		var finale = new TimelineMax({onComplete: logoBuild});
		finale.add(TweenMax.to($("#dot"), 1, {css:{backgroundColor:"rgb(238,105,123)",borderColor:"rgb(238,105,123)", bezier:animationPoints.playoutBezier.init}, ease:Power1.easeInOut}))
		finale.add(TweenMax.to($("#dot"), 1, {css:{bezier:animationPoints.playoutBezier.toLogo, scale: 0.4}, ease:Power1.easeInOut}))
	}

	var controller = new ScrollMagic.Controller();
	var dotTimeline = new TimelineMax({onComplete: playout});
	dotTimeline.add(TweenMax.to($("#dot"), .5, {css:{backgroundColor:"rgb(244,146,134)", borderColor:"rgb(255,255,255)", bezier:animationPoints.dotPath.lineA}, ease:Linear.easeNone, onComplete: clockIn}))
	dotTimeline.add(TweenMax.to($("#dot"), .6, {css:{backgroundColor:"rgb(245,214,121)",borderColor:"rgb(255,255,255)", bezier:animationPoints.dotPath.lineB}, ease:Linear.easeNone, onComplete: heartIn}))
	dotTimeline.add(TweenMax.to($("#dot"), .8, {css:{backgroundColor:"rgb(110,191,125)",borderColor:"rgb(255,255,255)", bezier:animationPoints.dotPath.lineC}, ease:Linear.easeNone, onComplete: handsIn}))
	dotTimeline.add(TweenMax.to($("#dot"), .6, {css:{backgroundColor:"rgb(3,94,148)",borderColor:"rgb(255,255,255)", bezier:animationPoints.dotPath.lineD}, ease:Linear.easeNone, onComplete: graphIn}))
	dotTimeline.add(TweenMax.to($("#dot"), .7, {css:{backgroundColor:"rgb(220,102,132)",borderColor:"rgb(255,255,255)", bezier:animationPoints.dotPath.lineE}, ease:Linear.easeNone}))

	var scene = new ScrollMagic.Scene({triggerElement: "#trigger", duration: $('#target').height() * speedVariant, offset: 0});
	scene.setTween(dotTimeline);
	scene.addTo(controller);
	scene.reverse(false);
		
});
	
}