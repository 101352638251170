$(function () {
  var $sections = $('.multi-step-form__frame');
  var maxIndex = $sections.length - 1;

  function navigateTo(index) {
    // Mark the current section with the class 'current'
    $sections
      .removeClass('activePage')
      .eq(index)
        .addClass('activePage');
  }

  function curIndex() {
    // Return the current index by looking at which section has the class 'current'
    return $sections.index($sections.filter('.activePage'));
  }

  // Previous button is easy, just go back
  $('.multi-step-form__control--prev').click(function() {
    navigateTo(curIndex() - 1);
  });

  // Next button goes forward iff current block validates
  $('.multi-step-form__control--next').click(function() {
    if ($('.multi-step-form').parsley().validate({group: 'block-' + curIndex()})) {
      navigateTo(curIndex() + 1);
      $('.activePage .form-control').first().focus();
    }
  });

  // Prepare sections by setting the `data-parsley-group` attribute to 'block-0', 'block-1', etc.
  $sections.each(function(index, section) {
    $(section).find(':input').attr('data-parsley-group', 'block-' + index);
  });
  navigateTo(0); // Start at the beginning

  document.onkeydown = function(e) {
    var evt = e || window.event;
    if ( curIndex() < maxIndex ) {
      if ( evt.keyCode == 13 ) {
        evt.preventDefault();
        $('.activePage .multi-step-form__control--next').click();
      }
    }
  };
});
