//Function to display social share in centered pop-up
function windowPopup(url, width, height) {
  // Calculate the position of the popup so
  // it’s centered on the screen.
  var left = (screen.width / 2) - (width / 2),
    top = (screen.height / 2) - (height / 2);

    window.open(
      url,
      "",
      "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=" + width + ",height=" + height + ",top=" + top + ",left=" + left
    );
}

$(document).ready( function() {
  $('.share-me__block').click( function(e) {
    if ( !$(this).hasClass('share-me__block--email') ) {
      var evt = e || window.event;
      evt.preventDefault();
      windowPopup($(this).attr("href"), 500, 375);
    }
  });
  $('.social-icon').click( function(e) {
    if ( !$(this).hasClass('social-icon--email') ) {
      var evt = e || window.event;
      evt.preventDefault();
      windowPopup($(this).attr("href"), 500, 375);
    }
  });
});

