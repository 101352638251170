jQuery.fn.extend({
  addParsleyErrorsContainers: function () {
    return this.each(function (formIndex) {
      $(this).find(':input').each( function(inputIndex) {
        var errID = 'form-message--'+formIndex+'-'+inputIndex;
        var errElement = $('<div class="form-message" id="'+errID+'">');
        $(this).attr('data-parsley-errors-container', '#'+errID);
        $(this).parent().append(errElement);
      });
    });
  }
});

$(document).ready( function() {

  $('.disable-on-submit').submit( function() {
    $(this).find(':submit').prop('disabled',true);
  });

  $('.form-action--search').submit( function() {
    $(this).find(':submit').text('Searching...');
  });

  $('.form-action--login').submit( function() {
    $(this).find(':submit').text('Logging in...');
  });

  $('.form-action--create').submit( function() {
    $(this).find(':submit').text('Creating...');
  });

  $('.form-action--update').submit( function() {
    $(this).find(':submit').text('Updating...');
  });

  $('.form-action--send').submit( function() {
    $(this).find(':submit').text('Sending...');
  });

  $('.parsley-errors').addParsleyErrorsContainers();

});

