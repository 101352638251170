function categoryBlockStates(index) {
  var first = $('.m-job-categories .category-block--first');
  var middle = $('.m-job-categories .category-block--middle');
  var last = $('.m-job-categories .category-block--last');

  if ( index == 1 ) {
    first.removeClass('shift-right');
    first.addClass('raised');
    middle.removeClass('raised');
    last.removeClass('shift-left');
  } else if ( index == -1 ) {
    first.removeClass('raised');
    first.addClass('shift-right');
    middle.addClass('raised');
    last.addClass('shift-left');
  } else if ( index == 2 ) {
    first.removeClass('shift-right');
    last.removeClass('shift-left');
    middle.removeClass('raised');
  } else if ( index == -2 ) {
    first.addClass('shift-right');
    last.addClass('shift-left');
    middle.addClass('raised');
  } else if ( index == 3 ) {
    last.removeClass('shift-left');
    last.addClass('raised');
    middle.removeClass('raised');
    first.removeClass('shift-right');
  } else if ( index == -3 ) {
    last.removeClass('raised');
    last.addClass('shift-left');
    middle.addClass('raised');
    first.addClass('shift-right');
  }
}

$(document).ready( function() {
  $('.m-job-categories .category-block--first').hover( function(){ categoryBlockStates(1) }, function(){ categoryBlockStates(-1) } );
  $('.m-job-categories .category-block--middle').hover( function(){ categoryBlockStates(2) }, function(){ categoryBlockStates(-2) } );
  $('.m-job-categories .category-block--last').hover( function(){ categoryBlockStates(3) }, function(){ categoryBlockStates(-3) } );
});
