function triggerResumeLoadingVisual() {
  $('.resume-button__icon').hide();
  $('.loading').removeClass('loading--hidden');
  $('.resume-button__title').text('Uploading...');
}

$(document).ready(function() {
  $('#revealEditor').on('click', function() {
    $('#resumeEditor').show();
  });

  $('#resumeInput').on('change', function() {
    triggerResumeLoadingVisual();
    setTimeout( $('#resumeUploadForm').submit(), 50 );
  });
});
